import DOMPurify from 'isomorphic-dompurify';
import { useContext } from 'react';

import HeaderFooterContext from '@/context/HeaderFooterContext';

const CopyRightComponent = () => {
  const headerFooterData = useContext(HeaderFooterContext);
  const { headerFooter } = headerFooterData;

  const copyRight = headerFooter[0]?.attributes?.Copyright;

  return (
    <div className="copyrights">
      <div className="container">
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(copyRight),
          }}
        ></div>
      </div>
    </div>
  );
};

export default CopyRightComponent;
